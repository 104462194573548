import React from "react";
import ERTFireBody from "../sections/standardPage/ERTFireService";
import { graphql } from 'gatsby'

const ERTFirePage = (data) =>
    <ERTFireBody data={data}/>

export default ERTFirePage;

export const query = graphql`
    {
        contentfulStandardPage(pageId: {eq: 5}) {
            heading
            subheading
            pageName
            pageId
            bodyText {
                json
            }
            twoColumns
            secondColumnBodyText {
                json
            }
            pageImage {
                fluid(maxWidth: 1180) {
                    ...GatsbyContentfulFluid
                }
            }
        }
    }`
